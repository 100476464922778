import Peony from "./Resources/Images/Gallery/pinkFlower.jpg";
import TwistedHazelCatkins from "./Resources/Images/Gallery/longRedPlant.jpg";
import LuffaFlower from "./Resources/Images/Gallery/yellowFlower.jpg";
import AndyTerry from "./Resources/Images/Gallery/andyTerry.jpg";
import AndyParsnip from "./Resources/Images/Gallery/andyTurnip.jpg";
import GarlicHarvest from "./Resources/Images/Gallery/onions.jpg";
import PotsGerminating from "./Resources/Images/Gallery/plantsInPots.jpg";
import BellPeppers from "./Resources/Images/Gallery/plantPots.jpg";
import Slowworm from "./Resources/Images/Gallery/snake.jpg";
import WeigelaFlowers from "./Resources/Images/Gallery/pinkFlowerBush.jpg";
import Roses from "./Resources/Images/Gallery/pinkFlowers.jpg";
import BeeFlower from "./Resources/Images/Gallery/beeFlower.jpg";
import PlantsAndSky from "./Resources/Images/Gallery/plantsAndSky.jpg";

const galleryImages = {
  Peony: {
    image: Peony,
    description: "Peony",
    alt: "peony pink flower",
  },
  TwistedHazelCatkins: {
    image: TwistedHazelCatkins,
    description: "Twisted Hazel Catkins",
    alt: "Twisted Hazel Catkins",
  },
  LuffaFlower: {
    image: LuffaFlower,
    description:
      "Luffa flower (I grow my own luffas to use as a scourer sponges to reduce our plastic usage)",
    alt: "Luffa flower",
  },
  AndyTerry: {
    image: AndyTerry,
    description: "My dad, Terry Walton & me!",
    alt: "My dad, Terry Walton & me!",
  },
  AndyParsnip: {
    image: AndyParsnip,
    description:
      "Me and a parsnip! (We’re virtually self-sufficient in vegetables)",
    alt: "Me and a parsnip!",
  },
  GarlicHarvest: {
    image: GarlicHarvest,
    description: "Garlic harvest",
    alt: "Garlic harvest",
  },
  Planting: {
    image: PotsGerminating,
    description:
      "Pots germinating seeds - I grow literally thousands of plants from seed each year",
    alt: "Pots germinating seeds",
  },
  BellPeppers: {
    image: BellPeppers,
    description: "Bell peppers in my greenhouse",
    alt: "Bell peppers in pots",
  },
  Slowworm: {
    image: Slowworm,
    description:
      "Slowworm. This is in my garden – I grow organically which helps encourage all sorts of wildlife.",
    alt: "slowworm",
  },
  WeigelaFlowers: {
    image: WeigelaFlowers,
    description: "Weigela flowers",
    alt: "Weigela flowers",
  },
  Roses: {
    image: Roses,
    description: "Roses. I can help with rose pruning and care.",
    alt: "Roses",
  },
  BeeFlower: {
    image: BeeFlower,
    description:
      "Bee on courgette flower (I love attracting wildlife into gardens!)",
    alt: "bee on yellow flower",
  },
  PlantsAndSky: {
    image: PlantsAndSky,
    description: "My garden during the peak of summer",
    alt: "plants and sky",
  },
};

export default galleryImages;
