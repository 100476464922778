import galleryImages from "./galleryInfo.jsx";
import { LazyLoadImage } from "react-lazy-load-image-component";

function GalleryCards() {
  function renderImageCard() {
    const galleryCards = [];

    for (const item in galleryImages) {
      galleryCards.push(
        <figure className="galleryCard" key={item}>
          <LazyLoadImage
            src={galleryImages[item].image}
            alt={galleryImages[item].alt}
          />
          <figcaption>{galleryImages[item].description}</figcaption>
        </figure>
      );
    }
    return galleryCards;
  }
  return (
    <section className="galleryCardContainer">{renderImageCard()}</section>
  );
}

export default GalleryCards;
