import "./Resources/SocialMediaIcons/css/all.css";
import React, { useState } from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import ScrollRestoration from "react-scroll-restoration";
import Navbar from "./Navbar.jsx";
import Header from "./Header.jsx";
import Footer from "./Footer.jsx";
import Home from "./Home.jsx";
import Services from "./Services.jsx";
import About from "./About.jsx";
import Gallery from "./Gallery.jsx";
import Reviews from "./Reviews.jsx";
import Blog from "./Blog.jsx";
import Contact from "./Contact.jsx";
import Privacy from "./Privacy.jsx";

function App() {
  const [hamburgerActive, setHamburgerActive] = useState("navBar");
  function handleClick() {
    hamburgerActive === "navBar"
      ? setHamburgerActive("navBar hamburgerActive")
      : setHamburgerActive("navBar");
  }

  return (
    <HashRouter>
      <Header handleClick={handleClick} hamburgerActive={hamburgerActive} />
      <Navbar handleClick={handleClick} hamburgerActive={hamburgerActive} />
      <main className="pageContent">
        <ScrollRestoration />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/services" component={Services} />
          <Route path="/about" component={About} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/reviews" component={Reviews} />
          <Route path="/blog" component={Blog} />
          <Route path="/contact" component={Contact} />
          <Route path="/privacy" component={Privacy} />
        </Switch>
      </main>
      <Footer />
    </HashRouter>
  );
}

export default App;
