function Privacy() {
  return (
    <main className="privacy">
      <h1 className="pageTitle">Privacy Policy</h1>
      <section>
        <h3>Introduction</h3>
        <p>
          At Andy B Garden Services, we store and process your personal data in
          line with the requirements of the General Data Protection Regulation
          (GDPR). The GDPR requires data controllers such as ourselves to
          document our lawful basis for the storage and use of your personal
          data. This page describes our policy for handling the data we may
          collect from users of this website. Use of this website implies your
          acceptance of the terms described below.
        </p>
        <h3>Data Controller</h3>
        <p>
          The data controller responsible for this website is Andrew Walton, who
          can be contacted by email at: andybgardenservices@gmail.com. This
          website may contain links to third-party websites, which will have
          their own data controllers and privacy policies. This privacy policy
          applies only to this website, and the owner of this website is not
          responsible for the content or conduct of any other website.
        </p>
        <h3>Purpose</h3>
        <p>
          We may collect and process your information for the following reasons:
          <ul>
            <li>
              To allow us to contact you directly, so that we can arrange or
              carry out the work that you request from usun build
            </li>
            <li>To improve the user experience of our website</li>
            <li>
              To allow us to analyse how visitors interact with our site in
              order to improve our site and our services
            </li>
            <li>
              To comply with GDPR stipulations pertaining to the storage of user
              data
            </li>
          </ul>
        </p>
        <h3>Data Collected</h3>
        <p>
          The types of data that we may collect and store include:
          <ul>
            <li>Your name and telephone number</li>
            <li>Home address</li>
            <li>Email address</li>
            <li>Tracking cookies, which may be stored in your browser.</li>
          </ul>
        </p>
        <p>
          Personal information such as your name, address, email and phone
          number are only collected for the purpose of direct contact to answer
          enquiries or to aid in the completion of work. We will not e-mail you
          in the future unless you have given us your consent to do so. Consent
          to store this type of information is inferred when you contact us.
        </p>

        <p>
          Tracking cookies may be used when you visit this website. A cookie is
          a small text file that is saved to your computer or mobile device,
          which may be retrieved on subsequent visits. These cookies are used to
          enhance the user experience. We may also use third-party cookies to
          collect statistics for aggregate analysis with tools such as Google
          Analytics. This helps us with marketing as it allows us to better
          understand the demographic of visitors to our site. You can choose to
          disable cookies - for instructions on how to handle and delete cookies
          please select the 'Help' menu in your browser.
        </p>
        <p>
          We will not sell or transfer your information to a third party unless
          required to do so by law. For more information about our privacy
          policy, please contact us using the details in the Data Controller
          section above.
        </p>
      </section>
    </main>
  );
}

export default Privacy;
