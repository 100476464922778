import GalleryCards from "./galleryCards.jsx";

function Gallery() {
  function moveDivisor() {
    let divisor = document.getElementById("afterImage");
    let slider = document.getElementById("slider");
    let afterTag = document.getElementById("afterTag");
    divisor.style.width = slider.value + "%";
    if (slider.value > 10) {
      afterTag.style.display = "block";
    } else {
      afterTag.style.display = "none";
    }
  }
  return (
    <main id="pageContent">
      <h1 className="pageTitle">Gallery</h1>
      <p className="gallerySubheader">
        These are some photos of my garden. Client photos to follow soon!
      </p>
      <section className="sliderImageContainer">
        <figure>
          <div className="imageScrollerDiv">
            <div className="comparison">
              <figure id="beforeImage">
                <div id="afterImage"></div>
                <p id="afterTag">After</p>
                <p id="beforeTag">Before</p>
              </figure>
              <input
                type="range"
                min="2"
                max="99"
                value="50"
                id="slider"
                onInput={moveDivisor}
              />
            </div>
          </div>
          <figcaption className="sliderImageCaption">
            Tap on the image above to see my work transform this garden
          </figcaption>
        </figure>
      </section>
      <GalleryCards />
      {/* <!--Middle section, to which new images can be added--> */}

      {/* <!--Bottom gallery section--> */}
      {/* <section className="galleryFeature gallery2">
        <figure className="featurePhoto">
          <img src={pinkFlowerBush} alt="Weigela flowers" />
          <figcaption>Weigela</figcaption>
        </figure>
        <section className="featureSubPhotos">
          <figure>
            <img src={pinkFlowers} alt="roses" />
            <figcaption>Roses</figcaption>
          </figure>

          <figure>
            <img src={beeFlower} alt="bee on yellow flower" />
            <figcaption>
              Bee on courgette flower (I love attracting wildlife into gardens!)
            </figcaption>
          </figure>

          <figure>
            <img src={plantsAndSky} alt="plants and sky" />
            <figcaption>My garden during the peak of summer</figcaption>
          </figure>
        </section>
      </section> */}
    </main>
  );
}
export default Gallery;
