import { NavLink } from "react-router-dom";

function Navbar(props) {
  return (
    <nav>
      <div
        onClick={props.handleClick}
        id="navBar"
        className={props.hamburgerActive}
      >
        <li>
          <NavLink exact to="/">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/services">Services</NavLink>
        </li>
        <li>
          <NavLink to="/about">About</NavLink>
        </li>
        <li>
          <NavLink to="/gallery">Gallery</NavLink>
        </li>
        <li>
          <NavLink to="/blog">Blog</NavLink>
        </li>
        {/*
        <li>
          <NavLink to="/reviews">Reviews</NavLink>
        </li> */}
        <li>
          <NavLink to="/contact">Contact</NavLink>
        </li>
      </div>
    </nav>
  );
}

export default Navbar;
