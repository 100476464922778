import { serviceInfo } from "./serviceInfo.jsx";

function fillServiceInfo(service) {
  return (
    <section key={Math.random()} className="serviceCard">
      <article className="imageUnderlay">
        <h2 className="hiddenServiceTitle">{service.heading}</h2>
        <p className="hiddenServiceText">{service.description}</p>
      </article>
      <figure className="imageOverlay">
        <img className="serviceImage" src={service.image} alt={service.alt} />
        <h2 className="serviceTitle">{service.heading}</h2>
      </figure>
    </section>
  );
}

function createCards() {
  const cards = [];

  for (const service in serviceInfo) {
    cards.push(fillServiceInfo(serviceInfo[service]));
  }

  return cards;
}

function services() {
  return (
    <main>
      <h1 className="pageTitle">Services</h1>
      <section className="serviceGrid">{createCards()}</section>
    </main>
  );
}

export default services;
