import BlogImage from "./Resources/Images/Blog/blogImage.jpg";

function Blog() {
  return (
    <main className="blog">
      <h1 className="pageTitle">Blog</h1>
      <section className="blogCard">
        <p className="comingSoon"> Blog coming soon!</p>
        <p>Watch this space for handy gardening hints, tips and advice.</p>

        <img src={BlogImage} alt="blog" className="blogImage" />
      </section>
    </main>
  );
}

export default Blog;
