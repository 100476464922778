import lawnMowing from "./Resources/Images/Services/lawnMowing.jpg";
import hedgeTrimming from "./Resources/Images/Services/hedgeTrimming.jpg";
import generalMaintenance from "./Resources/Images/Services/generalMaintenance.jpg";
import planting from "./Resources/Images/Services/planting.jpg";
import weedControl from "./Resources/Images/Services/weedControl.jpg";
import turfing from "./Resources/Images/Services/layingTurf.jpg";
import gardenMakeovers from "./Resources/Images/Services/gardenMakeover.jpg";
import pondInstallations from "./Resources/Images/Services/pondInstallation.jpg";
import plantingPlans from "./Resources/Images/Services/plantingPlans.jpg";
import coachingServices from "./Resources/Images/Services/coaching.jpg";

export const serviceInfo = {
  lawnMowing: {
    heading: "Lawn Mowing & Lawn Care",
    image: lawnMowing,
    alt: "lawn mowing service. Photo by Daniel Watson on Unsplash",
    description:
      "From basic regular lawn mowing service through to full lawn health-care services including weed & moss treatment, thatch removal and aeration.",
  },
  hedgeTrimming: {
    heading: "Hedge Trimming",
    image: hedgeTrimming,
    alt: "hedge trimming service. Photo by Anton Nikolov on Unsplash",
    description: "Seasonal hedge trimming as well as overall size reductions.",
  },

  generalMaintenance: {
    heading: "General Maintenance",
    image: generalMaintenance,
    alt: "general maintenance",
    description:
      "Everything to keep your garden looking exactly how you want it, including weeding, general tidying, leaf clearance, tree and shrub pruning, lawn maintenance.",
  },

  planting: {
    heading: "Planting",
    image: planting,
    alt: "planting service. Photo by Михаил Павленко on Unsplash",
    description: "Everything from annual bedding to trees.",
  },

  weedControl: {
    heading: "Weed Control",
    image: weedControl,
    alt: "weed control service. Photo by Herbert Goetsch on Unsplash",
    description: "A range of services available to keep those weeds at bay.",
  },
  turfing: {
    heading: "Turfing & Seeding New Lawns",
    image: turfing,
    alt: "turf laying service. Photo by CHUTTERSNAP on Unsplash",
    description: "New lawns created either by laying of turf or by seeding.",
  },
  gardenMakeovers: {
    heading: "Garden Makeovers",
    image: gardenMakeovers,
    alt: "garden makeover service",
    description:
      "Rejuvenate your outdoor space with a fresh look and makeover to your plan or via my design advice.",
  },

  pondInstallations: {
    heading: "Pond Installations & Water Features",
    image: pondInstallations,
    alt: "pond installation service. Photo by Zdeněk Macháček on Unsplash",
    description: "Ponds or water-features installed.",
  },
  plantingPlans: {
    heading: "Planting Plans",
    image: plantingPlans,
    alt: "planting plans. Photo by Jan Canty on Unsplash",
    description:
      "Garden planting plans provided for anything from a small border to a full garden.",
  },

  coachingServices: {
    heading: "Advisory/Coaching Services",
    image: coachingServices,
    alt: "coaching service. Photo by Nikola Jovanovic on Unsplash",
    description:
      "As a qualified and experienced teacher, I also love passing on my knowledge and skills to others.",
  },
};
