import { NavLink } from "react-router-dom";

function Home() {
  return (
    <main className="homePage">
      <section className="pictureBanner">
        <p>Turning outdoor spaces into blissful places</p>
      </section>
      <h1 className="pageTitle">Andy B Garden Services</h1>
      <div className="homeBlurb">
        <div className="homeQuestions">
          <p>
            <span className="doYou">Do you </span>want to make more use of your
            outdoor space? <br />
            <span className="doYou">Do you </span>struggle to find the time to
            get all the gardening jobs done?
            <br /> <span className="doYou">Do you </span> have jobs that you are
            not sure how to tackle?
            <br />
            <span className="doYou">Do you </span> want to relax in your garden
            rather than toil in it?
            <br />
            <br />
            If so, I can help! Working with you, I can help create/maintain the
            outdoor space to meet you and your family’s needs.
          </p>
        </div>

        <p className="homeQuoteText">
          For <span className="emphasisedAll">all</span> your garden-based
          needs, please <NavLink to="/contact">get in touch</NavLink> to arrange
          a free no-obligation quote.
        </p>
        <p>
          Andy B can provide all the{" "}
          <NavLink to="/services">garden services</NavLink> you need. From lawn
          cutting & hedge trimming to complete garden maintenance packages &
          garden design/makeovers. As an experienced and qualified teacher, I
          can also coach you in how to take care of your “outdoor room”
          yourself.
        </p>
      </div>
    </main>
  );
}

export default Home;
