import "./css/app.css";
import "./css/home.css";
import "./css/services.css";
import "./css/about.css";
import "./css/gallery.css";
import "./css/reviews.css";
import "./css/blog.css";
import "./css/contact.css";
import "./css/privacy.css";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App.jsx";

ReactDOM.render(<App />, document.getElementById("root"));
