import logo from "./Resources/Images/bannerLogo.png";

function Header(props) {
  function addHamburgerClass() {
    return props.hamburgerActive === "navBar" ? "" : "hamburgerActive";
  }
  return (
    <header className={"upperHeader " + addHamburgerClass()}>
      <figure className="bannerLogoContainer">
        <a href="index.html">
          {<img className="bannerLogo" src={logo} alt="Logo" />}
        </a>
      </figure>
      <section className="upperHeaderRight">
        <div className={"bannerContactContainer"}>
          <a href="mailto:andybgardenservices@gmail.com">
            <div>
              <h3>Contact Me</h3>
              <h4>Email</h4>
            </div>
            <i className="fas fa-envelope fa-3x"></i>
          </a>
          <a href="tel:+441989568726">
            <div>
              <h3>01989 568726</h3>
              <h4>Landline</h4>
            </div>
            <i className="fas fa-phone-alt fa-3x"></i>
          </a>
          <a href="tel:+447799063905">
            <div>
              <h3>07799 063905</h3>
              <h4>Mobile</h4>
            </div>
            <i className="fas fa-mobile-alt fa-3x"></i>
          </a>
        </div>
      </section>
      <button className="hamburgerButton">
        <i className="fas fa-bars fa-3x" onClick={props.handleClick}></i>
      </button>
      <button className="hamburgerCloseButton">
        <i className="fas fa-times fa-3x" onClick={props.handleClick}></i>
      </button>
    </header>
  );
}

export default Header;

// <HashRouter>
// <Header/>
// <Body/>
// <Footer/>
// </HashRouter>
